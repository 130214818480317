import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FancyChip } from '../FancyChip.tsx'
import { Logo } from '../LogoLS.tsx'
import { Tooltip } from './Tooltip.tsx'

export const Header = (props: { slogan: boolean }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <header className="header">
      <div className="container">
        <div className="header__text">
          <h1 className="h1">{t('header.title')}</h1>
          {props.slogan === true && (
            <div className="slogan h4">
              <Trans i18nKey="header.slogan">
                I am SomeGPT and I know (almost) everything published on
                <a href={import.meta.env.VITE_MAIN_SITE_LINK} target={'_blank'}>
                  someurl.chl
                </a>
                .
              </Trans>
            </div>
          )}
        </div>
        <div className="header__illustration">
          <div className="illustration">
            <div
              className="illustration__logo"
              onClick={() => {
                const searchParams = new URLSearchParams(window.location.search)
                const newSearchParams = new URLSearchParams()
                if (searchParams.has('q')) {
                  newSearchParams.set('q', searchParams.get('q') || '')
                }
                navigate(`/?${newSearchParams.toString()}`)
                window.location.reload()
              }}
            >
              <Logo />
            </div>
            <div className="illustration__background">
              <FancyChip />
            </div>
          </div>
        </div>
      </div>
      <Tooltip />
    </header>
  )
}
